import httpClient  from '../api'; 
const API_CONTROLLER = 'role-dept/'; 


export default { 
    update(data) { 
        let url = API_CONTROLLER + 'update';
        return httpClient.post(url, data);
    },

    getByStaffId(staffId) { 
        let url = API_CONTROLLER + 'get-by-staff-id';
        return httpClient.get(url, {
            params: {
                staffId: staffId
            }
        });
    },
}