<template>
  <div class="page-content">
    <div class="grid">
        <div class="row">
            <div class="col l-8 m-8 c-12">
                <div class="page-list-body">
                    <div class="page-list-search">
                        <div class="row">
                            <div class="col l-6 m-6 c-12">
                                <md-field>
                                    <label>Tìm theo tên</label>
                                    <md-input v-model="search.lastName"></md-input>
                                </md-field>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <md-field>
                                    <label>Tìm theo tên đăng nhập</label>
                                    <md-input v-model="search.userName"></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    <div class="table-content">
                        <table class="data-table">
                            <thead>
                                <tr>
                                    <th style="width: 100px;">#</th>
                                    <th class="left" style="width: 15%;">Nhóm quyền</th>
                                    <th class="left" style="width: 15%;">Tên đăng nhập</th>
                                    <th class="left" style="width: 15%;">Họ và tên</th>
                                    <th class="left" style="width: 25%;">Phòng ban</th>
                                    <th class="left" style="width: 25%;">Chi nhánh</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && pager.totalItems > 0">
                                <tr @click="selectedStaff(item.id, item.staffId)" v-for="(item, index) in data" :key="item.id" :class="{ 'active': entity.staffId == item.staffId }" class="row-staff" style="cursor: pointer;">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left">{{item.role.roleName}}</td>
                                    <td class="left">{{item.staffId}}</td>
                                    <td class="left">{{item.fullName}}</td>
                                    <td class="left">{{item.deptName}}</td>
                                    <td class="left">{{item.branchName}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-footer" v-if="pager.totalPages > 1">
                        <div class="grid">
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <div class="move-page">
                                        <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                        <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                    </div>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div class="paging">
                                        <div class="total-page">
                                            <div>Page</div> 
                                            <div style="padding-left:10px;">
                                                <md-field>
                                                    <md-select v-model="search.pageIndex">
                                                        <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                    </md-select>
                                                </md-field>
                                            </div>
                                            <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                        </div>
                                        <div class="row-of-page">
                                            <div style="padding-right:10px;">Rows/page:</div>
                                            <md-field>
                                                <md-select v-model="search.pageSize">
                                                    <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div class="record-of-page">
                                            <div style="padding-right:10px;">Xem:</div>
                                            <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="move-page">
                                        <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                        <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col l-4 m-4 c-12">
                <div class="grid">
                    <div class="row page-list-header" style="margin: 0;">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><span>Phân quyền phòng ban</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="radio-group pemission">
                                <label>Chức vụ</label>
                                <div class="form-control">
                                    <md-radio v-for="role in roles" :key="'role_' + role.id" v-model="entity.roleId" :value="role.id">{{ role.text }}</md-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="entity.roleId !== 1" class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="row">
                                <div v-for="(item, index) in entity.branchs" :key="'branch-' + item.branchId" class="col l-12 m-12 c-12">
                                    <div class="branch-list">
                                        <div style="padding-bottom: 10px; font-weight: bold;">Chi nhánh</div>
                                        <md-chip @click="selectedBranch(index)" :class="{'md-primary': item.selected == true }" md-clickable>{{ item.branchName }}</md-chip>
                                        <div style="padding-top: 10px;font-weight: bold;">Phòng ban</div>
                                        <div class="row dept-list">
                                            <div v-for="(dept, deptIndex) in item.depts" :key="'dept-' + dept.deptId" class="col l-6 m-6 c-12 dept-item">
                                                <md-chip @click="selectedDept(index, deptIndex)" :class="{'md-primary': dept.selected === true }" md-clickable>{{ dept.deptName }}</md-chip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import adminService from '../../../api/adminService';
import roleDeptService from '../../../api/roleDeptService';
import messageBox from '../../../utils/messageBox';
import common from '../../../mixins';
import { mapActions } from 'vuex';

export default {
    metaInfo: {
        title: 'Phân quyền phòng ban'
    },
    data() {
        return {
            loadding: false,
            submitted: false,
            search: { pageIndex: 1, pageSize: common.pageSize, userName: '', lastName: ''},
            pager: { totalPages: 1, totalItems: -1 },
            data: [],
            rowPerPageOptions: [],
            entity: [{ roleId: 6, adminId: 0, staffId: 0, branchId: 0, deptId: 0 }],
            roles: [{ text: 'TGĐ', id: 1 }, { text: 'PTGĐ', id: 2 }, { text: 'GĐCN', id: 3 }, { text: 'PGĐCN', id: 4 }, { text: 'TP/PP', id: 5 }]
        }
    },
    created(){
        this.getRoleDept();
        this.getData();
    },
    
    methods: {
        ...mapActions('common', ['setLoading']),

        selectedStaff(adminId, staffId){
            this.entity.adminId = adminId;
            this.entity.staffId = staffId;
            this.getRoleDept();
        },

        selectedBranch(index){
            if(this.entity.staffId === 0){
                messageBox.showWarning("Vui lòng chọn tài khoản trước");
                return;
            }
            if(this.entity.branchs[index].selected){
                this.entity.branchs[index].selected = false;
                for(let i = 0; i < this.entity.branchs[index].depts.length; i++ ){
                    this.entity.branchs[index].depts[i].selected = false;
                }
            }
            else{
                this.entity.branchs[index].selected = true;
                for(let i = 0; i < this.entity.branchs[index].depts.length; i++ ){
                    this.entity.branchs[index].depts[i].selected = true;
                }
            }
        },

        selectedDept(branchIndex, deptIndex){
            if(this.entity.branchs[branchIndex].selected == false){
                messageBox.showWarning('Vui lòng chọn chi nhánh trước');
                return;
            }
            if(this.entity.branchs[branchIndex].depts[deptIndex].selected){
                this.entity.branchs[branchIndex].depts[deptIndex].selected = false;
            }
            else{
                this.entity.branchs[branchIndex].depts[deptIndex].selected = true;
            }
        },

        
        submit(){
            if(this.entity.staffId == 0){
                messageBox.showWarning('Vui lòng chọn tài khoản');
                return;
            }
            if(this.entity.roleId == 0){
                messageBox.showWarning('Vui lòng chọn chức vụ');
                return;
            }
            this.update();
        },

        update(){
            this.setLoading(true);
            roleDeptService.update(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => {  this.setLoading(false); });
        },

        getData(){
            this.loadding = true;
            adminService.filter(this.search).then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data.items;
                    this.pager = response.data.pager;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loadding = false; });
        },

        onPage(page){
            if(page > this.pager.totalPages){
                return;
            }
            if(page == 0){
                return;
            }
            this.search.pageIndex = page;
            this.getData();
        },

        getRoleDept(){
            this.setLoading(true);
            roleDeptService.getByStaffId(this.entity.staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },
    },
    watch: {
        'search.userName': function () {
            this.search.pageIndex = 1;
            this.getData();
        },
        'search.lastName': function () {
            this.search.pageIndex = 1;
            this.getData();
        },
        'search.pageIndex': function () {
            this.getData();
        },
        'search.pageSize': function () {
            this.search.pageIndex = 1;
            this.getData();
        },
    },
}
</script>
<style scoped>
    .md-chip {
        width: 50%;
        text-align: center;
    }

    .dept-list .md-chip {
        width: 100%;
        text-align: center;
    }

    .dept-item {
        margin-bottom: 10px;
    }

    .dept-list{
        padding-left: 30px;
        width: 100%;
        padding-top: 10px;
    }
    .row-staff:hover {
        background-color: bisque;
    }
    .row-staff.active {
        background-color: bisque;
    }
    .branch-list{
        margin: 10px 5px;
        width: 97%;
        border: 1px solid #e0e0e0;
        padding: 10px 5px;
    }  
</style>
<style>
.pemission .md-radio .md-radio-label {
    padding-left: 10px !important;
}
</style>